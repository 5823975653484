import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  Link
} from '@material-ui/core';
import {
  Layers as ManageIcon,
  Package as ConfigureIcon,
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  Bell as NotificationsIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon
} from 'react-feather';
import { GiAbstract021 as GameIcon } from 'react-icons/gi';
import {
  MdDelete as DGIcon,
  MdUpdate as UGIcon,
  MdGames as GameI,
  MdRemoveRedEye as VGIcon,
  MdDeveloperBoard as PlanIcon,
  MdLoupe as CPIcon,
  MdDeleteSweep as DPIcon,
  MdPersonAdd as MAIcon,
  MdSend as MASIcon,
  MdLibraryBooks as MRIcon,
  MdLocationCity as MSIcon,
  MdVpnKey as SubK
} from 'react-icons/md';
import { RiAccountPinBoxLine as AdminUser } from 'react-icons/ri';
import './NavBar.css';
import NavItem from './NavItem';
import { ArrowDropDown } from '@material-ui/icons';

//const name = localStorage.getItem('username')
//const role = localStorage.getItem('role')

/* const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: role,
  name: name
}; */

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    flag: 'null',
    isDropDownopen: false
  },
  {
    href: '/app/manage',
    icon: ManageIcon,
    title: 'Manage',
    flag: 'dropdown',
    isDropDownopen: false,
    items: [
      {
        href: '/app/manage/accounts',
        icon: MAIcon,
        title: 'Accounts',
        flag: 'null'
      },
      {
        href: '/app/manage/schools',
        icon: MSIcon,
        title: 'Schools',
        flag: 'null'
      },
      //       {
      //         href: '/app/manage/subscription',
      //         icon: SubK,
      //         title: 'Subscription',
      //         flag: 'null'
      //       },
      {
        href: '/app/manage/users',
        icon: UsersIcon,
        title: 'Users',
        flag: 'null'
      },
      {
        href: '/app/manage/assignplans',
        icon: MASIcon,
        title: 'Assign Plans',
        flag: 'null'
      },
      {
        href: '/app/manage/reports',
        icon: MRIcon,
        title: 'Reports',
        flag: 'null'
      }
    ]
  },
  {
    href: '/app/config',
    icon: ConfigureIcon,
    title: 'Configure',
    flag: 'dropdown',
    isDropDownopen: false,
    items: [
      {
        href: '/app/config/game',
        icon: GameI,
        title: 'Game',
        flag: 'dropdown',
        items: [
          /* {
            href: '/app/config/game/deletegame',
            icon: DGIcon,
            title: 'Delete Game',
            flag: 'null'
          },
          {
            href: '/app/config/game/updategame',
            icon: UGIcon,
            title: 'Update Game',
            flag: 'null'
          }, */
          {
            href: '/app/config/game/viewgame',
            icon: VGIcon,
            title: 'View Games',
            flag: 'null'
          },
          {
            href: '/app/config/game/creategame',
            icon: GameIcon,
            title: 'Create Game V2',
            flag: 'null'
          }
          /* {
            href:'/app/config/game/variables/createvariable',
            icon: GameIcon,
            title: 'Create Variables',
            flag: 'null',
          }*/
        ]
      },
      {
        href: '/app/config/plans',
        icon: PlanIcon,
        title: 'Plans',
        flag: 'dropdown',
        isDropDownopen: false,
        items: [
          /* {
            href: '/app/config/plans/deleteplans',
            icon: DGIcon,
            title: 'Delete Plans',
            flag: 'null'
          }, */
          {
            href: '/app/config/plans/viewplans',
            icon: VGIcon,
            title: 'View Plans',
            flag: 'null'
          },
          {
            href: '/app/config/plans/createplans',
            icon: CPIcon,
            title: 'Create Plans',
            flag: 'null'
          }
        ]
      },
      {
        href: '/app/config/adminUser',
        icon: AdminUser,
        title: 'Admin User',
        flag: 'dropdown',
        items: [
          {
            href: '/app/config/adminUser/viewusers',
            icon: VGIcon,
            title: 'View Users',
            flag: 'null'
          },
          {
            href: '/app/config/adminUser/createadminuserview',
            icon: CPIcon,
            title: 'Create Admin User',
            flag: 'null'
          },
          {
            href: '/app/config/adminUser/deleteadminuserview',
            icon: DGIcon,
            title: 'Delete Admin User',
            flag: 'null'
          }
        ]
      }
    ]
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings',
    flag: 'null',
  },
  {
    href: '/app/notifications',
    icon: NotificationsIcon,
    title: 'Notifications',
    flag: 'null',
  }  
 
  
  /* ,
  {
    href: '/login',
    icon: LockIcon,
    title: 'Login',
    flag: 'null',
  },
  {
    href: '/register',
    icon: UserPlusIcon,
    title: 'Register',
    flag: 'null',
  },
  {
    href: '/404',
    icon: AlertCircleIcon,
    title: 'Error',
    flag: 'null',
  } */
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    overflow: 'hidden'
  },
  avatar: {
    cursor: 'pointer',
    align: 'left',
    width: 64,
    height: 64
  },
  navigationBorder: {
    borderLeft: 20,
    borderRight: 15,
    borderRightColor: '#e91e63',
    borderLeftColor: '#e91e63',
    borderLeftStyle: 'solid',
    borderRightStyle: 'solid',
    overflow: 'auto'
    //padding: 2
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [toggleOtherDropdowns, setToggleOtherDropdowns] = useState(false);

  const name = localStorage.getItem('username');
  const role = localStorage.getItem('role');

  const user = {
    avatar: '/static/images/avatars/avatar_6.png',
    jobTitle: role,
    name: name
  };

  // useEffect(() => {
  //   if (openMobile && onMobileClose) {
  //     //onMobileClose();
  //   }
  //   //console.log("openMobile,openMobile",openMobile,onMobileClose)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      //m={1}
      //p={2}
      className={classes.navigationBorder}
    >
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/dashboard"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h4"
          align="left"
        >
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2" align="left">
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box /* p={2} */>
        <List alignItems="center">
          {/* {items.map((item) => (
              <NavItem
                level={1}
                items={item.items}
                href={item.href}
                key={item.title}
                title={item.title}
                flag={item.flag}
                icon={item.icon}
              />
          ))} */}

          {items
            .filter(
              role === 'user'
                ? item => item.title !== 'Configure'
                : item => item.title !== null
            )
            .map(item => (
              <NavItem
                toggleOtherDropdowns={toggleOtherDropdowns}
                setToggleOtherDropdowns={setToggleOtherDropdowns}
                level={1}
                items={item.items}
                href={item.href}
                key={item.title}
                title={item.title}
                flag={item.flag}
                icon={item.icon}
              />
            ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={2} m={2} bgcolor="background.dark" />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
