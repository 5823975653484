/* eslint-disable max-len */
import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import DashboardView from 'src/views/Dashboard/DashboardView';
import CreategameView from 'src/views/Configure/Games/CreateGameV2/CreateGameView';
import DeletegameView from 'src/views/Configure/Games/DeleteGame/DeletegameView';
import GameView from 'src/views/Configure/Games/ViewGame/GameView';
import UpdategameView from 'src/views/Configure/Games/UpdateGame/UpdategameVIew';
import CreateVariableView from 'src/views/Configure/Games/Variables/CreateVariable/CreateVariableView';
import DeleteVariableView from 'src/views/Configure/Games/Variables/DeleteVariable/DeleteVariableView';
import CreatePlanView from 'src/views/Configure/Plans/CreatePlans/CreatePlanView';
import PublishedPlanView from 'src/views/Configure/Plans/ViewPlans/PublishedPlanView';
import DeletePlansView from 'src/views/Configure/Plans/DeletePlans/DeletePlansView';
import AccountView from 'src/views/Manage/Accounts/AccountView';
import AssignPlanView from 'src/views/Manage/AssignPlans/AssignplanView';
import AssignPlanSchoolSectionView from 'src/views/Manage/AssignPlans/AssignplanView/AssignPlanSchoolSection';
import ReportsView from 'src/views/Manage/Reports/ReportsView';
import ReportCard from 'src/views/Manage/Reports/ReportsView/ReportCard';
import SchoolView from 'src/views/Manage/Schools/SchoolView';
import UserView from 'src/views/Manage/Users/UsersView';
import SubscriptionView from 'src/views/Manage/Subscription/SubscriptionView';
import SubscriptionDetailView from 'src/views/Manage/Subscription/SubscriptionView/SubscriptionDetails';
import LicenseDetailView from 'src/views/Manage/Subscription/SubscriptionView/LicenseDetails';
import AddSubscriptionView from 'src/views/Manage/Subscription/SubscriptionView/AddSubscription';
import NotFoundView from 'src/views/errors/NotFoundView';
import LoginView from 'src/views/auth/LoginView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import SectionsView from 'src/views/Manage/Schools/SchoolView/Sections';
import ChildrenView from 'src/views/Manage/Schools/SchoolView/Children';
import CreateAdminUserView from 'src/views/Configure/AdminUser/CreateAdminUser/CreateAdminUserView';
import DeleteAdminUserView from 'src/views/Configure/AdminUser/DeleteAdminUser/DeleteAdminUserView';
import ViewUsers from 'src/views/Configure/AdminUser/ViewUsers/ViewUsersView';

import PlanView from 'src/views/Manage/Schools/SchoolView/Plans';
import Subscription_SchoolView from 'src/views/Manage/Schools/SchoolView/Subscription_School';
import Subscription_SectionView from 'src/views/Manage/Schools/SchoolView/Subscription_Section';
import Subscription_AddView from 'src/views/Manage/Schools/SchoolView/Subscription_Add';
import LicenseListView from 'src/views/Manage/Schools/SchoolView/LicenseList';
import GameRewardView from 'src/views/Configure/Games/CreateGameV2/CreateGameView/gameReward';
import { GameContextProvider } from './views/Configure/Context/GameContext';
import Notifications from './notifications';

//import AssignPlanDialogBox from './views/Manage/AssignPlans/AssignplanView/AssignPlanDialogBox';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      {
        path: 'manage',
        element: <MainLayout />,
        children: [
          { path: 'accounts', element: <AccountView /> },
          //{ path: 'assignplans', element: <AssignPlanView /> },
          { path: 'assignplans', element: <SchoolView /> },
          //{ path: 'assignPlanDialogBox', element: <AssignPlanDialogBox /> },
          {
            path: 'assignPlanSchoolSectionView',
            element: <AssignPlanSchoolSectionView />
          },
          { path: 'reports', element: <ReportsView /> },
          { path: 'reportCard', element: <ReportCard /> },
          { path: 'schools', element: <SchoolView /> },
          { path: 'sections', element: <SectionsView /> },
          { path: 'children', element: <ChildrenView /> },
          { path: 'plans', element: <PlanView /> },
          { path: 'users', element: <UserView /> },
          { path: 'subs_school', element: <Subscription_SchoolView /> },
          { path: 'subs_section', element: <Subscription_SectionView /> },
          { path: 'subs_add', element: <Subscription_AddView /> },
          { path: 'subscription', element: <SubscriptionView /> },
          { path: 'licenseList', element: <LicenseListView /> },
          {
            //path: 'subscriptionDetail/:id',
            path: 'subscriptionDetail',
            element: <SubscriptionDetailView />
          },
          { path: 'licenseDetail', element: <LicenseDetailView /> },
          { path: 'addSubscription', element: <AddSubscriptionView /> }
        ]
      },
      {
        path: 'config',
        element: <MainLayout />,
        children: [
          {
            path: 'game',
            //element: <MainLayout />,
            children: [
              { path: 'creategame', element: <GameContextProvider><CreategameView /></GameContextProvider> },
              { path: 'creategame/:id', element: <CreategameView /> },
              { path: 'deletegame', element: <DeletegameView /> },
              { path: 'updategame', element: <UpdategameView /> },
              { path: 'gameReward', element: <GameRewardView /> },
              {
                path: 'variables',
                //element: <MainLayout />,
                children: [
                  { path: 'createvariable', element: <CreateVariableView /> },
                  { path: 'deletevariable', element: <DeleteVariableView /> }
                ]
              },
              { path: 'viewgame', element: <GameContextProvider><GameView /></GameContextProvider> }
            ]
          },
          {
            path: 'plans',
            //element: <MainLayout />,
            children: [
              { path: 'createplans', element: <CreatePlanView /> },
              { path: 'createplans/:id', element: <CreatePlanView /> },
              { path: 'viewplans', element: <PublishedPlanView /> },
              { path: 'deleteplans', element: <DeletePlansView /> }
            ]
          },
          {
            path: 'adminUser',
            //element: <MainLayout />,
            children: [
              { path: 'createadminuserview', element: <CreateAdminUserView /> },
              {
                path: 'createadminuserview/:userName',
                element: <CreateAdminUserView />
              },
              { path: 'deleteadminuserview', element: <DeleteAdminUserView /> },
              { path: 'viewusers', element: <ViewUsers /> }
            ]
          }
        ]
      },
      { path: 'dashboard', element: <DashboardView /> },

      { path: 'settings', element: <SettingsView />, children: [
        { path: 'notifications', element: <Notifications /> }
      ] },
      { path: 'notifications', element: <Notifications /> }
      
      // { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app" /> },
       { path: '*', element: <Navigate to="/app" /> }
    ]
  }
];

export default routes;
