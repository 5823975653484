import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import TelegramIcon from '@mui/icons-material/Telegram';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
const Notifications = () => {
    const [rows, setRows] = useState([]);
    const [formData, setFormData] = useState({});
    const [sending, setSending] = useState(false);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleSelectedRows = (row) => {
      const existingRow = selectedRows.find((r) => r.id === row.id);
      if (existingRow) {
        setSelectedRows(selectedRows.filter((r) => r.id !== row.id));
      } else {
        setSelectedRows([...selectedRows, row]);
      }
    }
    const hanldeAllSelectedRows = (rows) => {
      if (selectedRows.length === rows.length) {
        setSelectedRows([]);
      } else {
        setSelectedRows(rows);
      }
    }
    const handleEdit = (id) => {
      const row = rows.find((r) => r.id === id);
      setFormData({
        subject: row.subject,
        content: row.message,
        id: row.id
      });
      setOpen(true);
      setUpdate(true);
    }
    const handleDelete = () => {
      const ids = selectedRows.map((r) => parseInt(r));
      console.log(ids,selectedRows, parseInt(selectedRows[0]));
      
      if(ids.length<1){
        toast.error('You must select at least one row to delete',{
          toastId: 'notification-delete'
        });
        return
      }
      axios.post(`${process.env.REACT_APP_BASE_APP_URL}/notifications/delete`, ids)
      .then((res)=>{
        toast.success('Notification deleted successfully');
        setUpdate(!update);
      })
      .catch((err)=>{
        console.log(err);
      })
    }

    
    const columns = [
        
        { field: 'subject', headerName: 'Subject', width: 300 },
        { field: 'message', headerName: 'Message', flex: 1 }, // 50% width dynamically
        { field: 'status', headerName: 'Status', width: 150 },
       
        { field: 'date', headerName: 'Date', width: 200 },
        {
          field: 'actions',
          headerName: 'Actions',
          width: 150,
          sortable: false,
          renderCell: (params) => (
            <>
              <IconButton
                aria-label="edit"
                onClick={() => handleEdit(params.row.id)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
              disabled = {sending}
                aria-label="resend"
                onClick={() => handleResendNotification(params.row.id)}
              >
                <TelegramIcon />
              </IconButton>
            </>
          ),
        },
      ];

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BASE_APP_URL}/notification/get-all`)
    .then((res)=>{
        const data  = res.data.response.body.message;
        const temp = [];
        for (const d of data) {
            console.log(d);
            
            const obj = {
                id: d.id,
                date: d.createdAt,
                message: d.message,
                subject: d.subject,
                status: d.status
            }
            temp.push(obj);
        }
        setRows(temp)
    }).catch((error)=>{
        toast.error(error.message || 'Something went wrong')
    })
  },[update])

  const handleCloseDialogBox = () => {
    setOpen(false);
    setFormData({})
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSendNotification = () => {
    if(formData.subject.length<1 || formData.content.length<1){
      toast.error('Subject and content are required',{
        toastId: 'notification-send'
      });
      return;
    }
    if(formData.id){
      axios.post(`${process.env.REACT_APP_BASE_APP_URL}/notifications-update/${parseInt(formData.id)}`, formData)
      .then((res)=>{
          toast.success(res.data.response.body.message);
          setUpdate(!update)
          handleCloseDialogBox();
      }).catch((error)=>{
          toast.error(error.message || 'Something went wrong')
      })
      return;
    }

    axios.post(`${process.env.REACT_APP_BASE_APP_URL}/send-notifications`, formData)
    .then((res)=>{
        toast.success("Notification sent successfully");
        setUpdate(!update)

        handleCloseDialogBox();
    }).catch((error)=>{
        toast.error(error.message || 'Something went wrong')
    })
  }
  const handleSelectionChange = (selection) => {
    setSelectedRows(selection.selectionModel);
  };

  const handleResendNotification = (id) => {
    setSending(true);
    axios.get(`${process.env.REACT_APP_BASE_APP_URL}/notifications/resent/${id}`)
    .then((res)=>{
        toast.success("Notification resent successfully");
        setUpdate(!update)
        setSending(false);
    }).catch((error)=>{
        toast.error(error.message || 'Something went wrong')
        setSending(false);
    })
  }
  
  return (
    <Box>
     <Box display="flex" justifyContent="flex-end" p={2} >
          <Button onClick={()=> setOpen(true)} color="primary" variant="contained">
            <AddCircleOutlinedIcon />
          </Button>
          <Button onClick={()=> handleDelete()} style={{marginLeft: '10px'}} color="primary" variant="contained">
            <DeleteOutlineOutlinedIcon />
          </Button>
        </Box>
   <DataGrid
      rows={rows}
      columns={columns}
      pageSize={5}
      disableSelectionOnClick
      checkboxSelection
      onSelectionModelChange={(newSelection) => handleSelectionChange(newSelection)}
      autoHeight
      on
    />
    <Dialog
                //maxWidth="md"
                fullWidth
                open={open}
                onClose={handleCloseDialogBox}
                aria-labelledby="form-dialog-title"
                PaperProps={{
                  style: {
                    borderRadius: 10,
                    borderWidth: 5,
                    borderStyle: 'solid',
                    borderColor: '#e91e63'
                  }
                }}
                BackdropProps={{
                  style: {
                    backgroundColor: 'rgba(0,0,0,0.1)'
                  }
                }}
              >
                <div style={{ backgroundColor: '#008080' }}>
                  <DialogTitle
                    style={{
                      color: 'white'
                    }}
                    align="center"
                    id="form-dialog-title"
                  >
                    New Notification
                  </DialogTitle>
                </div>
                <DialogContent>
                
                  <TextField
                    name="subject"
                    id="subject"
                    label="Subject"
                    fullWidth
                    type='text'
                    required
                    margin="dense"
                    value={formData.subject ?? ""}
                    variant="outlined"
                    onChange={handleChange}
                  >
                    
                  </TextField>

                  <TextField
                    name="content"
                    id="message"
                    label="Message"
                    fullWidth
                    type='text'
                    required
                    margin="dense"
                    value={formData.content ?? ""}
                    variant="outlined"
                    onChange={handleChange}
                  >
                    
                  </TextField>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={handleCloseDialogBox}
                    color="primary"
                    style={{
                      textTransform: 'none',
                      fontSize: '1rem'
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSendNotification}
                    color="primary"
                    style={{
                      textTransform: 'none',
                      fontSize: '1rem',
                      backgroundColor: '#008080',
                      color: '#fff'
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
   </Box>
    
  );
}

export default Notifications;
